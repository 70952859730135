import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';

import { HeadInner } from '../components/Head';
import { Layout } from '../components/layout';
import { colors } from '../theme/colors';

export const Head = () => (
  <HeadInner
    path='/concept/'
    title='コンセプト'
    description='paild（ペイルド）のコンセプトです。ペイルドは、何を目指しているのか？法人支出の最適化で、社会の生産性を上げる。導入後も共に寄り添うサービスでありたい'
  />
);

const ConceptPage = () => {
  const links = [{ name: 'paildのコンセプト', path: '/concept/' }];

  return (
    <Layout links={links}>
      <div
        css={{
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '32px 0',
        }}
      >
        <div css={HeadingWrapperStyle}>
          <p>Concept</p>
          <h1>
            ペイルドは、
            <br />
            何を目指しているのか？
          </h1>
        </div>
        <section css={SectionStyle}>
          <h2>
            「法人支出の最適化で、
            <br />
            社会の生産性を上げる」
          </h2>
          <p>
            ペイルドは、法人支出の課題をテクノロジーの力で解決し、
            <br />
            社会の生産性の向上に真剣に取り組んでいます。
          </p>
          <p>
            これまで見過ごされてきた法人支出の課題は多く、
            <br />
            どこか1箇所だけ改善しても、業務全体の生産性はあがりません。
          </p>
          <p>
            ペイルドはツール提供にとどまらず、
            <br />
            お客様それぞれが持つ理想のDXを実現するためにビジネスプロセス全体を支援します。
          </p>
          <p>
            顧客業務を深く理解し、
            <br />
            ペイルドがあるから業務全体の生産性があがる、
            <br />
            そんななくてはならない存在になっていきたいと思っています。
          </p>
        </section>
        <div css={TopImageWrapper}>
          <div css={TopImageAbsoluteWrapper}>
            <div css={TopImageRelativeWrapper}>
              <StaticImage
                src='../images/concept/concept-1.svg'
                alt='concept-1'
                quality={100}
                placeholder='none'
                css={{
                  position: 'absolute',
                  top: 0,
                  right: 80,
                  aspectRatio: '305 / 202',

                  '@media(max-width: 1119px)': {
                    width: 200,
                    top: 160,
                    right: 80,
                  },
                  '@media(max-width: 768px)': {
                    width: 152,
                    top: 0,
                    right: 'calc(50% - 40px)',
                  },
                }}
              />
              <StaticImage
                src='../images/concept/concept-2.png'
                alt='concept-2'
                quality={100}
                placeholder='none'
                css={{
                  width: 314,
                  height: 314,
                  borderRadius: 157,
                  position: 'absolute',
                  top: 160,
                  right: -40,

                  '@media(max-width: 1119px)': {
                    width: 200,
                    height: 200,
                    top: 200,
                    right: -40,
                  },
                  '@media(max-width: 768px)': {
                    width: 140,
                    height: 140,
                    top: 0,
                    right: 'calc(50% - 150px)',
                  },
                }}
                imgStyle={{ borderRadius: 157 }}
              />
              <StaticImage
                src='../images/concept/concept-3.png'
                alt='concept-3'
                quality={100}
                placeholder='none'
                css={{
                  width: 252,
                  height: 252,
                  borderRadius: 126,
                  position: 'absolute',
                  top: 360,
                  right: 260,
                  '@media(max-width: 1119px)': {
                    width: 160,
                    height: 160,
                    top: 360,
                    right: 140,
                  },
                  '@media(max-width: 768px)': {
                    width: 108,
                    height: 108,
                    top: 'auto',
                    bottom: 0,
                    right: 'calc(50% + 50px)',
                  },
                }}
                imgStyle={{ borderRadius: 126 }}
              />
              <StaticImage
                src='../images/concept/concept-4.svg'
                alt='concept-4'
                quality={100}
                placeholder='none'
                css={{
                  position: 'absolute',
                  top: 560,
                  right: 40,
                  aspectRatio: '412 / 240',
                  '@media(max-width: 1119px)': {
                    width: 280,
                    top: 480,
                    right: -20,
                  },
                  '@media(max-width: 768px)': {
                    width: 178,
                    top: 'auto',
                    bottom: 0,
                    right: 'calc(50% - 110px)',
                  },
                }}
              />
            </div>
          </div>
        </div>
        <section css={SectionStyle}>
          <h2>
            安心して
            <br />
            従業員に配布できる法人カード
          </h2>
          <p>
            従来、コストやガバナンスの問題から、
            <br />
            法人カードは限定的な活用にとどまっており、
            <br />
            立替経費や小口現金といった非効率な業務が、
            <br />
            未だに残り続けています。
          </p>
          <p>
            そこで、法人の皆様が安心して幅広い用途にご利用いただけるよう、
            <br />
            気軽に始められ、且つ適切なガバナンスが効いた運用ができる、
            <br />
            paildという新しい法人カードを作りました。
          </p>
          <p>
            さらにカード決済に加えて、
            <br />
            仕訳や領収書の回収・管理まで一気通貫で対応が可能です。
          </p>
          <p>
            カードを使う側も、管理する側も、
            <br />
            みんなpaildを通じて、働きやすくなります。
          </p>
          <p>もはや単なる法人カードではありません。</p>
        </section>
        <section css={SectionStyle}>
          <h2>
            導入後も
            <br />
            共に寄り添うサービスでありたい
          </h2>
          <p>
            システムを導入して終わり、そうはさせません。
            <br />
            ペイルドは、各分野の
            <br />
            エキスパートが集まっています。
          </p>
          <p>
            導入後も法人の皆様に寄り添い、
            <br />
            法人支出を最適化していきます。
            <br />
            運用のお悩みはお気軽にご相談ください。
          </p>
        </section>
        <h2 css={BottomMessageStyle}>
          <strong>ペイルド。</strong>
          <br />
          それは、新しい法人カードから広がる
          <br />
          スムーズな働き方。
        </h2>
        <div css={BottomImageWrapper}>
          <div css={BottomImageAbsoluteWrapper}>
            <div css={BottomImageRelativeWrapper}>
              <StaticImage
                src='../images/concept/concept-5.png'
                alt='concept-5'
                quality={100}
                placeholder='none'
                css={{
                  width: 490,
                  height: 490,
                  borderRadius: 245,
                  position: 'absolute',
                  bottom: 198,
                  right: -80,
                  '@media(max-width: 1119px)': {
                    width: 320,
                    height: 320,
                    bottom: 280,
                    right: -40,
                  },
                  '@media(max-width: 768px)': {
                    width: 175,
                    height: 175,
                    bottom: 0,
                    right: 'calc(50% - 30px)',
                  },
                }}
                imgStyle={{ borderRadius: 245 }}
              />
              <StaticImage
                src='../images/concept/concept-6.svg'
                alt='concept-6'
                quality={100}
                placeholder='none'
                css={{
                  position: 'absolute',
                  bottom: 0,
                  right: 144,
                  aspectRatio: '166 / 202',
                  '@media(max-width: 1119px)': {
                    width: 220,
                    bottom: 140,
                    right: 140,
                  },
                  '@media(max-width: 768px)': {
                    width: 166,
                    bottom: 0,
                    right: 'calc(50% - 150px)',
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const HeadingWrapperStyle = css({
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px 96px 17px',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    padding: '0 17px 16px 17px',
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },

  h1: {
    fontSize: 56,
    color: colors.primary,
    br: {
      display: 'none',
    },

    '@media(max-width: 1119px)': {
      fontSize: 40,
      br: {
        display: 'inline',
      },
    },

    '@media(max-width: 768px)': {
      fontSize: 24,
    },
  },

  p: {
    fontSize: 16,
    lineHeight: 1,
    color: colors.primary,

    '@media(max-width: 1119px)': {
      fontSize: 14,
    },
  },
});

const SectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1120,
  padding: '32px 17px',
  position: 'relative',
  gap: 40,

  h2: {
    fontSize: 32,
    br: {
      display: 'none',
    },
  },

  p: {
    fontSize: 16,
  },

  '@media(max-width: 1119px)': {
    padding: '16px 17px',
    maxWidth: 640,
    gap: 30,

    h2: {
      fontSize: 20,
      br: {
        display: 'inline',
      },
    },

    p: {
      fontSize: 14,
    },
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,

    p: {
      br: {
        display: 'none',
      },
    },
  },
});

const BottomMessageStyle = css({
  fontSize: 40,
  color: colors.black,
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  marginTop: 64,

  strong: {
    color: colors.primary,
    fontWeight: 700,
  },

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    fontSize: 28,
    marginTop: 32,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
    fontSize: 18,
  },
});

const TopImageWrapper = css({
  position: 'relative',
  width: '100%',
  maxWidth: 1320,
  zIndex: -1,

  '@media(max-width: 1119px)': {
    maxWidth: 840,
  },

  '@media(max-width: 768px)': {
    height: 220,
    margin: '24px 0',
  },
});

const TopImageAbsoluteWrapper = css({
  position: 'absolute',
  width: '100%',
  height: 700,
  top: -480,
  '@media(max-width: 768px)': {
    height: '100%',
    top: 0,
  },
});

const TopImageRelativeWrapper = css({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const BottomImageWrapper = css({
  position: 'relative',
  width: '100%',
  maxWidth: 1320,
  zIndex: -1,

  '@media(max-width: 1119px)': {
    maxWidth: 840,
  },

  '@media(max-width: 768px)': {
    height: 202,
  },
});

const BottomImageAbsoluteWrapper = css({
  position: 'absolute',
  width: '100%',
  height: 700,
  bottom: 0,

  '@media(max-width: 768px)': {
    height: '100%',
  },
});

const BottomImageRelativeWrapper = css({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export default ConceptPage;
